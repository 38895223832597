<template>
  <div class="card" :class="darkDark !== 'white' ? 'darkCard' : 'card'">
    <!-- <div class="card-header" v-if="title">{{ title }}</div> -->
    <div class="card-body">
      <p class="card-title"></p>

      <div
        id="dataTables-example_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <table
              class="border-collapse border border-green-800 w-full table-auto"
              id="dataTables-example"
              :style="{ color, overflowX: 'hidden' }"
            >
              <thead
                class="bg-gray-200 text-gray-600 text-sm leading-normal"
                :style="{
                  color:
                    headColor || (this.darkDark !== 'white' ? '#cccccc' : '#000000'),
                  // background: headBackground || (this.darkDark? '#B0B0B0': '#111111'),
                  height: '30px',
                }"
              >
                <tr>
                  <th
                    :class="'text-center'"
                    v-for="(col, index) in columns"
                    :key="index"
                    :style="{
                      textAlign: 'center',
                      width: '100%',
                      position: 'sticky',
                      top: '-1px',
                      transition: 'var(--transition)',
                      background:
                        headBackground ||
                        (this.darkDark !== 'white'
                          ? 'var(--nav1BgClr)'
                          : '#B0B0B0'),
                    }"
                  >
                    <div class="custom-select-table">
                      <div class="selected" style="line-height: 11px">
                        <span
                          class="uppercase"
                          :style="{ fontSize: `${headFontSize}px` }"
                          >{{ col.label.split(" ")[0] }}</span
                        >
                        <br />
                        <span v-if="col.label.split(' ')[1]"
                          >({{ col.label.split(" ")[1] }})
                        </span>
                        <br />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length > 0" class="text-black text-sm">
                <tr
                  class="border-gray-200"
                  v-for="(row, i) in rows"
                  :key="i"
                  :style="
                    i % 2 == 0
                      ? {
                          color:
                            color || (this.darkDark !== 'white' ? '#fff' : ''),
                          backgroundColor:
                            background ||
                            (this.darkDark !== 'white'
                              ? 'var(--navBar2Bg)'
                              : ''),
                          transition: 'var(--transition)',
                        }
                      : {
                          color:
                            color || (this.darkDark !== 'white' ? '#fff' : ''),
                          backgroundColor: 'var(--root_nav)',
                          transition: 'var(--transition)',
                        }
                  "
                >
                  <td
                    class="text-left overflow-hidden whitespace-nowrap auto-font py-1.5"
                    :style="{
                      // fontSize: `${headFontSize}px`,
                      color:
                        parseInt(row[col.field]) >=
                          parseInt(
                            $store.state.rect.rects[widgetPropertyIndex][
                              col.label.split(' ').join('').toLowerCase() +
                                'StartRange'
                            ]
                          ) &&
                        parseInt(row[col.field]) <=
                          parseInt(
                            $store.state.rect.rects[widgetPropertyIndex][
                              col.label.split(' ').join('').toLowerCase() +
                                'EndRange'
                            ]
                          )
                          ? alertColor || 'red'
                          : color || (this.darkDark !== 'white' ? '#fff' : ''),
                      // height: `${height * 0.1}px`,
                      textAlign: 'center',
                    }"
                    v-for="(col, j) in columns"
                    :key="j"
                  >
                    <div v-if="col.display" v-html="col.display(row)"></div>
                    <span v-else>{{
                      row[col.field]
                        ? parseFloat(row[col.field]).toFixed(1)
                        : ""
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="row"
          v-if="rows.length > 0"
          :style="{ width: `${width}%` }"
        ></div>
        <div class="row" v-else>
          <div
            :style="{
              color: this.darkDark !== 'white' ? '#cccccc' : 'var(--textColor)',
            }"
            class="col-sm-12 text-center"
          >
          <span class="nodata-msg"> {{ messages.noDataAvailable }}</span>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
String.prototype.format = function () {
  var args = arguments;
  return this.replace(/{([0-9]*)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUpdate,
  nextTick,
} from "vue";

export default defineComponent({
  name: "my-table",
  props: {
    alertColor: {
      type: String,
    },
    widgetPropertyIndex: {
      type: String,
    },
    tableId: {
      type: String,
    },
    color: {
      type: String,
    },
    background: {
      type: String,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    headColor: {
      type: String,
    },
    headBackground: {
      type: String,
    },
    headFontSize: {
      type: String,
      default: "14",
    },
    isLoading: {
      type: Boolean,
      require: true,
    },
    isReSearch: {
      type: Boolean,
      require: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 100,
    },
    page: {
      type: Number,
      default: 1,
    },
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
        };
      },
    },
    tableConfiguration: Object,
    messages: {
      type: Object,
      default: () => {
        return {
          pagingInfo: "Showing {0}-{1} of {2}",
          pageSizeChangeLabel: "Row count:",
          gotoPageLabel: "Go to page:",
          noDataAvailable: "No data",
        };
      },
    },
  },
  data() {
    return {
      dropdownPopoverShow: false,
      backgroundColor: this.background,
    };
  },
  computed: {
    darkDark() {
      console.log("table dark dark : ", this.$store.state.rect.darkmode);
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      e.stopPropagation();
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },

  mounted() {
    // color:  (this.$store.state.rect.rects[this.tableId][col.field+'StartRange'] > 100 && this.$store.state.rect.rects[id][col.field+'EndRange'] < 150) ? '#ff0000' :this.$store.state.rect.rects[id][col.field+'EndRange'] > 150 ? '#FF4500':row[col.field]>300?'#FF4500' :color,
    //
    //   "eeeeeeeeeeeeeeeeeeee",
    //   this.$store.state.rect.rects[this.tableId].table
    // );
  },
  setup(props, { emit }) {
    const setting = reactive({
      isCheckAll: false,
      keyColumn: computed(() => {
        let key = "";
        props.columns.forEach((col) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      page: props.page,
      pageSize: props.pageSize,
      maxPage: computed(() => {
        if (props.total <= 0) {
          return 0;
        }
        let maxPage = Math.floor(props.total / setting.pageSize);
        let mod = props.total % setting.pageSize;
        if (mod > 0) {
          maxPage++;
        }
        return maxPage;
      }),

      offset: computed(() => {
        return (setting.page - 1) * setting.pageSize + 1;
      }),

      limit: computed(() => {
        let limit = setting.page * setting.pageSize;
        return props.total >= limit ? limit : props.total;
      }),
      pagging: computed(() => {
        let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2;
        if (setting.maxPage - setting.page <= 2) {
          startPage = setting.maxPage - 4;
        }
        startPage = startPage <= 0 ? 1 : startPage;
        let pages = [];
        for (let i = startPage; i <= setting.maxPage; i++) {
          if (pages.length < 5) {
            pages.push(i);
          }
        }
        return pages;
      }),
    });

    const rowCheckbox = ref([]);
    if (props.hasCheckbox) {
      onBeforeUpdate(() => {
        rowCheckbox.value = [];
      });

      watch(
        () => setting.isCheckAll,
        (state) => {
          let isChecked = [];
          rowCheckbox.value.forEach((val) => {
            if (val) {
              val.checked = state;
              if (val.checked == true) {
                isChecked.push(val.value);
              }
            }
          });
          emit("return-checked-rows", isChecked);
        }
      );
    }

    const checked = () => {
      let isChecked = [];
      rowCheckbox.value.forEach((val) => {
        if (val && val.checked == true) {
          isChecked.push(val.value);
        }
      });
      emit("return-checked-rows", isChecked);
    };

    const clearChecked = () => {
      rowCheckbox.value.forEach((val) => {
        if (val && val.checked == true) {
          val.checked = false;
        }
      });
      emit("return-checked-rows", []);
    };

    const doSort = (order) => {
      let sort = "asc";
      if (order == props.sortable.order) {
        if (props.sortable.sort == "asc") {
          sort = "desc";
        }
      }
      let offset = (setting.page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      emit("do-search", offset, limit, order, sort);

      if (setting.isCheckAll) {
        setting.isCheckAll = false;
      } else {
        if (props.hasCheckbox) {
          clearChecked();
        }
      }
    };

    /**
     * 切換頁碼
     *
     * @param number page     新頁碼
     * @param number prevPage 現在頁碼
     */
    const changePage = (page, prevPage) => {
      setting.isCheckAll = false;
      let order = props.sortable.order;
      let sort = props.sortable.sort;
      let offset = (page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      if (!props.isReSearch || page > 1 || page == prevPage) {
        emit("do-search", offset, limit, order, sort);
      }
    };
    // 監聽頁碼切換
    watch(() => setting.page, changePage);

    /**
     * 切換顯示筆數
     */
    const changePageSize = () => {
      if (setting.page === 1) {
        changePage(setting.page, setting.page);
      } else {
        setting.page = 1;
        setting.isCheckAll = false;
      }
    };
    watch(() => setting.pageSize, changePageSize);

    const prevPage = () => {
      if (setting.page == 1) {
        return false;
      }
      setting.page--;
    };

    const movePage = (page) => {
      setting.page = page;
    };

    const nextPage = () => {
      if (setting.page >= setting.maxPage) {
        return false;
      }
      setting.page++;
    };

    watch(
      () => props.rows,
      () => {
        if (props.isReSearch) {
          setting.page = 1;
        }
        nextTick(function () {
          let localElement = document.getElementsByClassName("is-rows-el");
          emit("is-finished", localElement);
        });
      }
    );

    if (props.hasCheckbox) {
      return {
        setting,
        rowCheckbox,
        checked,
        doSort,
        prevPage,
        movePage,
        nextPage,
      };
    } else {
      return {
        setting,
        doSort,
        prevPage,
        movePage,
        nextPage,
      };
    }
  },
});
</script>

<style scoped>
.checkbox-th {
  width: 1%;
}

.both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}

.sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px !important;
}

.asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.desc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.loading-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.loading-content {
  display: table-cell;
  vertical-align: middle;
}

.loading-icon {
  display: block;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #fff; */
  background-color: var(--widgetBg);
  background-clip: border-box;
  /* padding: 20px; */
}

.darkCard {
  background: var(--navBar2Bg);
  transition: var(--transition);
}

select {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: auto;
  padding: 0px;
  margin-bottom: 0px;
}

table {
  table-layout: fixed;
  width: 100%;
  color: #212529;
  /* border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0px 0;
}

th {
  text-align: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  /* padding: 0.75rem; */
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table-border {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  vertical-align: middle;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  /* padding: 0.75rem; */
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* td{
  display: inline-table;
} */
.overflow-auto {
  background: #727174;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px; */
}

div.dataTables_wrapper div.dataTables_paginate {
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

select {
  width: 100%;
  box-shadow: 0 10px 25px rgb(0 0 0 / 50%);
  font-size: 8px;
  padding: 0 1.5em;
  background: #0e1016;
  color: white;
  /* appearance: none; */
  border: 0;
}

.custom-select-table {
  position: relative;
  font-size: calc(0.6em + 0.148vmin + 0.148vmax) !important;
}

.custom-arrow {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  background: red;
  height: 100%;
  width: 4rem;
  pointer-events: none;
}

.custom-arrow::before,
.custom-arrow::after {
  --size: 1em;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-arrow::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid red;
  top: 35%;
}

.custom-arrow::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid red;
  top: 65%;
}

.auto-font {
  font-size: calc(0.6em + 0.148vmin + 0.148vmax) !important;
}
.nodata-msg{
  font-size: 14px;
}
</style>
